import http from '../services/http';

export default class Email {
  static fetchAllEmails() {
    return http.get('/EmailContacts', {
      params: {
        filter: {
          order: 'createdAt desc'
        }
      }
    });
  }

  static fetchEmailInfo(id) {
    return http.get(`/EmailContacts/${id}`);
  }
}

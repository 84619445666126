import React from 'react';
import { Link } from 'react-router-dom';

export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      <div>
        <h3>
          <Link to="/emails">
            <img style={{ height: 45 }} src={`${process.env.PUBLIC_URL}/images/${collapsed ? 'favicon.svg' : 'logo-text.png'}`} alt="logo" />
          </Link>
        </h3>
      </div>
    </div>
  );
};

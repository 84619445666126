import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import {
  Redirect,
  Route,
  withRouter
} from 'react-router-dom';

import $ from 'jquery';
import { ThemeProvider } from 'styled-components';
import { Layout } from 'antd';

import { get } from 'lodash';

import Topbar from '../Topbar/Topbar';
import Sidebar from '../Sidebar/Sidebar';

import themes from '../../settings/themes';
import { siteConfig, themeConfig } from '../../settings';
import { toggleAll, initServiceWorker } from '../../actions/common';
import { fetchAesKey } from '../../actions/user';
import http from '../../services/http';

import { ModalError } from '../../components/modalAlert';

import './global.css';
import AppHolder from './commonStyle';

const { Content, Footer } = Layout;

window.$ = $;
window.jQuery = $;

class App extends Component {
  componentWillMount() {
    const { login, aesKeyRes, fetchAesKey } = this.props;
    const accessToken = get(login, 'result.accessToken', '');

    if (accessToken) {
      http.setAuthorizationHeader(accessToken);
    }

    const aesKey = get(aesKeyRes, 'result.key', '');
    const aesIv = get(aesKeyRes, 'result.iv', '');

    if (aesKey && aesIv) {
      http.setInterceptorRequest(aesKey, aesIv);
    } else {
      fetchAesKey()
        .then(() => {
          const { aesKeyRes } = this.props;

          const error = get(aesKeyRes, 'error.data.error.message', '');

          if (error) {
            return ModalError('Error!', error);
          }
        });
    }
  }

  componentDidMount() {
    // let script = document.createElement('script');
    // script.src = process.env.PUBLIC_URL + '/js/jquery-ui/jquery-ui.min.js';
    // script.async = true;
    // document.body.appendChild(script);
    //
    // script = document.createElement('script');
    // script.src = process.env.PUBLIC_URL + '/js/image-resize.min.js';
    // script.async = true;
    // document.body.appendChild(script);

    // this.props.initServiceWorker();
  }

  render() {
    const { url } = this.props.match;
    const { height } = this.props;
    const appHeight = window.innerHeight;

    return (
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <AppHolder>
          <Layout style={{ height: appHeight }}>
            <Debounce time="1000" handler="onResize">
              <WindowResizeListener
                onResize={windowSize => {
                  this.props.toggleAll(
                    windowSize.windowWidth,
                    windowSize.windowHeight
                  );
                }}
              />
            </Debounce>

            <Topbar url={url} path={this.props.path} />

            <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
              <Sidebar url={url} path={this.props.path}/>

              <Layout
                className="isoContentMainLayout"
                style={{
                  height
                }}
              >
                <Content
                  className="isomorphicContent"
                  style={{
                    padding: '70px 0 0',
                    flexShrink: '0',
                    background: '#f1f3f6',
                    position: 'relative'
                  }}
                >
                  {this.props.children}
                </Content>

                <Footer
                  style={{
                    background: '#ffffff',
                    textAlign: 'center',
                    borderTop: '1px solid #ededed'
                  }}
                >
                  {siteConfig.footerText}
                </Footer>
              </Layout>
            </Layout>
          </Layout>
        </AppHolder>
      </ThemeProvider>
    );
  }
}

const AppRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          isAuthenticated ? (
            <App {...rest}>
              <Component {...props} />
            </App>
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          )
        );
      }}
    />
  );
};

export default withRouter(connect(
  state => ({
    login: state.user.login,
    height: state.common.height
  }),
  dispatch => bindActionCreators({
    toggleAll,
    fetchAesKey,
    initServiceWorker
  }, dispatch)
)(AppRoute));

import http from '../services/http';

export default class Category {
  static fetchCategories() {
    return http.get('/categories');
  }

  static fetchCategory(id) {
    return http.get(`/categories/${id}`);
  }

  static createCategory(body) {
    return http.post('/categories', body);
  }

  static updateCategory(id, body) {
    return http.patch(`/categories/${id}`, body);
  }

  static deleteCategory(id) {
    return http.delete(`/categories/${id}`);
  }
}



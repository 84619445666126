import CryptoJS from 'crypto-js';
import { isString } from 'lodash';

export const encryptAES = (data, key, iv) => {
  if (!isString(data)) {
    return null;
  }

  const cipher = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
  });

  return cipher.toString();
};

export const decryptAES = (_encrypted, _key) => {
  if (!isString(_encrypted)) {
    return null;
  }

  const iv = CryptoJS.enc.Base64.parse(_key.substr(0, 22));
  const key = CryptoJS.enc.Base64.parse(_key);
  const encrypted = _encrypted.toString(CryptoJS.enc.Base64);

  const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const generateAESKeyRandom = () => {
  const wordArrayRandom = CryptoJS.lib.WordArray.random(16);
  const AES256keyRandom = CryptoJS.enc.Hex.stringify(wordArrayRandom)
    .toLocaleUpperCase();

  return AES256keyRandom;
};

export const hmacSha512 = (data, key) => {
  const encrypted = CryptoJS.HmacSHA512(data, key);

  return CryptoJS.enc.Hex.stringify(encrypted);
};

export const encodeBase64 = (data) => {
  const wordArray = CryptoJS.enc.Utf8.parse(data);
  const base64 = CryptoJS.enc.Base64.stringify(wordArray);

  return base64;
};

import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore
} from 'redux';
import {
  routerMiddleware,
  routerReducer
} from 'react-router-redux';
import { persistStore } from 'redux-persist';
import { get } from 'lodash';
import thunk from 'redux-thunk';

import { clearUserData } from './actions/user';

import reducers from './reducers';
import handleApiServerStatus from './middlewares/tokenExpired';

const createHistory = require('history');

const authenticationMiddleware = store => next => (action) => {
  if (
    get(action, 'payload.error.data.error.code') === 'AUTHORIZATION_REQUIRED' ||
    get(action, 'payload.error.data.error.name') === 'TokenExpiredError' ||
    get(action, 'payload.error.data.error.name') === 'JWT Expired'
  ) {
    return store.dispatch(clearUserData());
  }

  return next(action);
};

function configureStoreProd(initialState) {
  const history = createHistory.createBrowserHistory();
  const routeMiddleware = routerMiddleware(history);
  const middlewares = [
    // Add other middleware on this line...

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    thunk,
    routeMiddleware,
    authenticationMiddleware
  ];

  const store = createStore(
    combineReducers({
      ...reducers,
      router: routerReducer
    }),
    initialState,
    compose(
      applyMiddleware(...middlewares, handleApiServerStatus)
    ));

  const persistor = persistStore(store);

  return { store, history, persistor };
}

function configureStoreDev(initialState) {
  const history = createHistory.createBrowserHistory();
  const routeMiddleware = routerMiddleware(history);
  const middlewares = [
    // Add other middleware on this line...

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    thunk,
    routeMiddleware,
    authenticationMiddleware
  ];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const store = createStore(
    combineReducers({
      ...reducers,
      router: routerReducer
    }),
    initialState,
    composeEnhancers(
      applyMiddleware(...middlewares, handleApiServerStatus)
    ));

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers').default; // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
  }

  const persistor = persistStore(store);

  return { store, history, persistor };
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;


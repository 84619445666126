import http from '../services/http';

export default class Member {
  static fetchMembers() {
    return http.get('/members');
  }

  static fetchMemberInfo(memberId) {
    return http.get(`/members/${memberId}`);
  }

  static createMember(body) {
    return http.post('/members', body);
  }

  static updateMember(body, memberId) {
    return http.patch(`/members/${memberId}`, body);
  }

  static deleteMember(memberId) {
    return http.delete(`/members/${memberId}`);
  }
}

import React from 'react';
import { Route, Redirect, Switch, BrowserRouter as Router } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { connect } from 'react-redux';
import { get } from 'lodash';

import App from './containers/App';
import asyncComponent from './helpers/AsyncFunc';
import routes from './containers/App/routes';

const PublicRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !isAuthenticated
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/emails',
            state: { from: props.location }
          }}
        />
    }
  />
);

const MainRoutes = ({ history, isAuthenticated }) => {
  return (
    <ConnectedRouter history={history}>
      <Router>
        <Switch>
          <Route exact path="/">
            {isAuthenticated ? <Redirect to="/emails" /> : <Redirect to="/login" />}
          </Route>

          <PublicRoute
            exact
            path={'/login'}
            isAuthenticated={isAuthenticated}
            component={asyncComponent(() => import('./containers/Pages/Login'))}
          />

          {routes.map((route, i) => <App key={i} isAuthenticated={isAuthenticated} {...route} />)}

        </Switch>
      </Router>
    </ConnectedRouter>
  );
};

export default connect(state => ({
  isAuthenticated: Boolean(get(state, 'user.login.result.id'))
}))(MainRoutes);

import { handleActions } from 'redux-actions';

const initialState = {
  posts: {
    result: [],
    requesting: false,
    error: null
  },
  post: {
    result: null,
    requesting: false,
    error: null
  },
  created: {
    result: null,
    requesting: false,
    error: null
  },
  updated: {
    result: null,
    requesting: false,
    error: null
  },
  deleted: {
    result: null,
    requesting: false,
    error: null
  }
};

export const post = handleActions({
  FETCH_POSTS_REQUEST: (state) => ({
    ...state,
    posts: {
      ...state.posts,
      requesting: true,
      error: null
    }
  }),
  FETCH_POSTS_SUCCESS: (state, { payload }) => ({
    ...state,
    posts: {
      ...state.posts,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_POSTS_FAIL: (state, { payload }) => ({
    ...state,
    posts: {
      ...state.posts,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  FETCH_POST_REQUEST: (state) => ({
    ...state,
    post: {
      ...initialState.post,
      requesting: true,
      error: null
    }
  }),
  FETCH_POST_SUCCESS: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_POST_FAIL: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  CREATE_POST_REQUEST: (state) => ({
    ...state,
    created: {
      ...state.created,
      requesting: true,
      error: null
    }
  }),
  CREATE_POST_SUCCESS: (state, { payload }) => ({
    ...state,
    created: {
      ...state.created,
      requesting: false,
      result: payload.data,
      error: null
    },
    posts: {
      ...state.posts,
      result: [
        payload.data,
        ...state.posts.result
      ]
    }
  }),
  CREATE_POST_FAIL: (state, { payload }) => ({
    ...state,
    created: {
      ...state.created,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  UPDATE_POST_REQUEST: (state) => ({
    ...state,
    updated: {
      ...state.updated,
      requesting: true,
      error: null
    }
  }),
  UPDATE_POST_SUCCESS: (state, { payload }) => ({
    ...state,
    updated: {
      ...state.updated,
      requesting: false,
      result: payload.data,
      error: null
    },
    posts: {
      ...state.posts,
      result: state.posts.result.map(item => item.id === payload.data.id ? payload.data : item)
    }
  }),
  UPDATE_POST_FAIL: (state, { payload }) => ({
    ...state,
    updated: {
      ...state.updated,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  DELETE_POST_REQUEST: (state) => ({
    ...state,
    deleted: {
      ...state.deleted,
      requesting: true,
      error: null
    }
  }),
  DELETE_POST_SUCCESS: (state, { payload }) => ({
    ...state,
    deleted: {
      ...state.deleted,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  DELETE_POST_FAIL: (state, { payload }) => ({
    ...state,
    deleted: {
      ...state.deleted,
      requesting: false,
      result: null,
      error: payload.error
    }
  })

}, initialState);

export default post;

import { handleActions } from 'redux-actions';

const initialState = {
  emails: {
    result: [],
    error: null,
    requesting: false
  },
  emailInfo: {
    result: null,
    error: null,
    requesting: false
  }
};

export const email = handleActions({
  /** FETCH ALL EMAILS **/
  FETCH_ALL_EMAILS_REQUEST: (state) => ({
    ...state,
    emails: {
      ...state.emails,
      requesting: true
    }
  }),
  FETCH_ALL_EMAILS_SUCCESS: (state, { payload }) => ({
    ...state,
    emails: {
      ...state.emails,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_ALL_EMAILS_FAIL: (state, { payload }) => ({
    ...state,
    emails: {
      ...state.emails,
      requesting: false,
      error: payload.error
    }
  }),
  /** FETCH EMAIL INFO **/
  FETCH_EMAIL_INFO_REQUEST: (state) => ({
    ...state,
    emailInfo: {
      ...state.emailInfo,
      requesting: true
    }
  }),
  FETCH_EMAIL_INFO_SUCCESS: (state, { payload }) => ({
    ...state,
    emailInfo: {
      ...state.emailInfo,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_EMAIL_INFO_FAIL: (state, { payload }) => ({
    ...state,
    emailInfo: {
      ...state.emailInfo,
      requesting: false,
      error: payload.error
    }
  })
}, initialState);

export default email;

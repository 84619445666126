import { Modal } from 'antd';

const ModalSuccess = (title, messages, handlePressOk) => {
  Modal.success({
    title,
    content: messages,
    onOk() {
      if (handlePressOk) {
        handlePressOk();
      }
    }
  });
};

const ModalError = (title, messages, handlePressOk) => {
  Modal.error({
    title,
    content: messages,
    onOk() {
      if (handlePressOk) {
        handlePressOk();
      }
    }
  });
};

const ModalWarning = (title, messages) => {
  Modal.warning({
    title,
    content: messages
  });
};

export {
  ModalSuccess,
  ModalError,
  ModalWarning
};

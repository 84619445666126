import { handleActions } from 'redux-actions';

const initialState = {
  members: {
    result: [],
    error: null,
    requesting: false
  },
  memberInfo: {
    result: null,
    error: null,
    requesting: false
  },
  createMember: {
    result: {},
    error: null,
    requesting: false
  },
  updateMember: {
    result: null,
    error: null,
    requesting: false
  },
  deleteMember: {
    result: null,
    error: null,
    requesting: false
  }
};

export const member = handleActions({
  /** FETCH MEMBERS **/
  FETCH_MEMBERS_REQUEST: (state) => ({
    ...state,
    members: {
      ...state.members,
      requesting: true
    }
  }),
  FETCH_MEMBERS_SUCCESS: (state, { payload }) => ({
    ...state,
    members: {
      ...state.members,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_MEMBERS_FAIL: (state, { payload }) => ({
    ...state,
    members: {
      ...state.members,
      requesting: false,
      error: payload.error
    }
  }),

  /** FETCH MEMBER INFO **/
  FETCH_MEMBER_INFO_REQUEST: (state) => ({
    ...state,
    memberInfo: {
      ...state.memberInfo,
      requesting: true
    }
  }),
  FETCH_MEMBER_INFO_SUCCESS: (state, { payload }) => ({
    ...state,
    memberInfo: {
      ...state.memberInfo,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_MEMBER_INFO_FAIL: (state, { payload }) => ({
    ...state,
    memberInfo: {
      ...state.memberInfo,
      requesting: false,
      error: payload.error
    }
  }),

  /** CREATE MEMBERS **/
  CREATE_MEMBER_REQUEST: (state) => ({
    ...state,
    createMember: {
      ...state.createMember,
      requesting: true
    }
  }),
  CREATE_MEMBER_SUCCESS: (state, { payload }) => ({
    ...state,
    createMember: {
      ...state.createMember,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  CREATE_MEMBER_FAIL: (state, { payload }) => ({
    ...state,
    createMember: {
      ...state.createMember,
      requesting: false,
      error: payload.error
    }
  }),

  /** UPDATE MEMBER **/
  UPDATE_MEMBER_REQUEST: (state) => ({
    ...state,
    updateMember: {
      ...state.updateMember,
      requesting: true
    }
  }),
  UPDATE_MEMBER_SUCCESS: (state, { payload }) => ({
    ...state,
    updateMember: {
      ...state.updateMember,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  UPDATE_MEMBER_FAIL: (state, { payload }) => ({
    ...state,
    updateMember: {
      ...state.updateMember,
      requesting: false,
      error: payload.error
    }
  }),

  /** DELETE MEMBER **/
  DELETE_MEMBER_REQUEST: (state) => ({
    ...state,
    deleteMember: {
      ...state.deleteMember,
      requesting: true
    }
  }),
  DELETE_MEMBER_SUCCESS: (state, { payload }) => ({
    ...state,
    deleteMember: {
      ...state.deleteMember,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  DELETE_MEMBER_FAIL: (state, { payload }) => ({
    ...state,
    deleteMember: {
      ...state.deleteMember,
      requesting: false,
      error: payload.error
    }
  })
}, initialState);

export default member;

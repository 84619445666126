import http from '../services/http';

export default class Post {
  static fetchPosts(page, limit, search, categoryId) {
    let body = {};
    if (categoryId) {
      body = {
        params: {
          filter: {
            include: ['category', 'createBy'],
            where: {
              categoryId,
              and: [
                {
                  or: [
                    { title: { regexp: new RegExp(search, 'i').toString() } },
                    { slug: { regexp: new RegExp(search, 'i').toString() } }
                  ]
                }
              ]
            }
          },
          page,
          limit
        }
      };
    } else {
      body = {
        params: {
          filter: {
            include: ['category', 'createBy'],
            where: {
              or: [
                { title: { regexp: new RegExp(search, 'i').toString() } },
                { slug: { regexp: new RegExp(search, 'i').toString() } }
              ]
            }
          },
          page,
          limit
        }
      };
    }
    return http.get('/posts', body);
  }

  static fetchPost(id) {
    return http.get(`/posts/${id}`, {
      params: {
        filter: {
          include: ['category', 'createBy']
        }
      }
    });
  }

  static createPost(body) {
    return http.post('/posts', body);
  }

  static updatePost(id, body) {
    return http.patch(`/posts/${id}`, body);
  }

  static deletePost(id) {
    return http.delete(`/posts/${id}`);
  }
}



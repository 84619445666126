import localForage from 'localforage';
import { persistReducer } from 'redux-persist';

import user from './user';
import common from './common';
import member from './member';
import category from './category';
import post from './post';
import email from './email';

const userPersistConfig = {
  key: 'user',
  storage: localForage,
  whitelist: ['login', 'aesKey']
};

const commonPersistConfig = {
  key: 'common',
  storage: localForage,
  whitelist: []
};

export default {
  user: persistReducer(userPersistConfig, user),
  common: persistReducer(commonPersistConfig, common),
  member,
  category,
  post,
  email
};

const options = [
  // {
  //   key: 'dashboard',
  //   label: 'sidebar.dashboard',
  //   leftIcon: 'ion-earth'
  // },
  // {
  //   key: 'categories',
  //   label: 'sidebar.categories',
  //   leftIcon: 'ion-clipboard'
  // },
  {
    key: 'posts',
    label: 'sidebar.posts',
    leftIcon: 'ion-edit'
  },
  { key: 'users',
    label: 'sidebar.manageUsers',
    leftIcon: 'ion-person-stalker'
  },
  {
    key: 'members',
    label: 'sidebar.members',
    leftIcon: 'ion-person-stalker'
  },
  {
    key: 'emails',
    label: 'sidebar.email',
    leftIcon: 'ion-email'
  }
];
export default options;

import { createAction } from 'redux-actions';
import * as serviceWorker from '../serviceWorker';
import { getView } from '../utils/common';

export const closeAll = createAction('CLOSE_ALL');
export const changeCurrent = createAction('CHANGE_CURRENT');
export const changeOpenKeys = createAction('CHANGE_OPEN_KEYS');
export const toggleCollapsed = createAction('TOGGLE_COLLAPSED');
export const handleToggleAll = createAction('HANDLE_TOGGLE_ALL');
export const toggleOpenDrawer = createAction('TOGGLE_OPEN_DRAWER');

export const toggleAll = (width, height) => (dispatch) => {
  const view = getView(width);
  const collapsed = view !== 'DesktopView';
  dispatch(handleToggleAll({ collapsed, view, height }));
};

export const checkServiceWorker = createAction('CHECK_SERVICE_WORKER');

export const initServiceWorker = () => (dispatch, getState) => {
  const releasedDate = getState().common.releasedDate || '';
  const currentTimeEnv = process.env.REACT_APP_TIMESTAMP.toString();
  if (releasedDate.toString() !== currentTimeEnv) {
    dispatch(checkServiceWorker(currentTimeEnv));
    serviceWorker.unregister();
  } else {
    serviceWorker.register();
  }
};

import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: '/',
    exact: true,
    component: asyncComponent(() => import('../Pages/Dashboard'))
  },
  {
    path: '/dashboard',
    exact: true,
    component: asyncComponent(() => import('../Pages/Dashboard'))
  },
  {
    path: '/blankPage',
    exact: true,
    component: asyncComponent(() => import('../blankPage'))
  },
  {
    path: '/users',
    exact: true,
    component: asyncComponent(() => import('../Pages/Users'))
  },
  {
    path: '/users/:id',
    exact: true,
    component: asyncComponent(() => import('../Pages/UserDetails'))
  },
  {
    path: '/members',
    exact: true,
    component: asyncComponent(() => import('../Pages/Members'))
  },
  {
    path: '/members/createMember',
    exact: true,
    component: asyncComponent(() => import('../Pages/Members/CreateMember'))
  },
  {
    path: '/members/:id',
    exact: true,
    component: asyncComponent(() => import('../Pages/Members/EditMember'))
  },
  {
    path: '/categories',
    exact: true,
    component: asyncComponent(() => import('../Pages/Categories'))
  },
  {
    path: '/posts',
    exact: true,
    component: asyncComponent(() => import('../Pages/Posts'))
  },
  {
    path: '/posts/new',
    exact: true,
    component: asyncComponent(() => import('../Pages/Posts/CreatePost'))
  },
  {
    path: '/posts/:id',
    exact: true,
    component: asyncComponent(() => import('../Pages/Posts/EditPost'))
  },
  {
    path: '/emails',
    exact: true,
    component: asyncComponent(() => import('../Pages/Emails'))
  }
];

export default routes;

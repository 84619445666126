import {
  createAction,
  createActions
} from 'redux-actions';
import { get } from 'lodash';

import Api from '../api';
import http from '../services/http';

/* clear data after user logout */
const setInitialUserData = createAction('SET_INITIAL_USER_DATA');
const userLogout = createAction('USER_LOGOUT');

export const clearUserData = () => (dispatch) => {
  http.setAuthorizationHeader('');
  dispatch(setInitialUserData());
  dispatch(userLogout());
};

/** LOGIN **/
const { loginRequest, loginSuccess, loginFail } = createActions({
  LOGIN_REQUEST: () => { },
  LOGIN_SUCCESS: data => ({ data }),
  LOGIN_FAIL: error => ({ error })
});

export const login = credentials => (dispatch) => {
  dispatch(loginRequest());

  return Api.User.login(credentials)
    .then(({ data }) => {
      dispatch(loginSuccess(data));
    })
    .catch((error) => {
      dispatch(loginFail(error));
    });
};

/** VERIFY 2FA **/
const { verifyF2aRequest, verifyF2aSuccess, verifyF2aFail } = createActions({
  VERIFY_F2A_REQUEST: () => { },
  VERIFY_F2A_SUCCESS: data => ({ data }),
  VERIFY_F2A_FAIL: error => ({ error })
});

export const verifyF2a = body => (dispatch) => {
  dispatch(verifyF2aRequest());

  return Api.User.verifyF2a(body)
    .then(({ data }) => {
      const token = get(data, 'accessToken', '');

      http.setAuthorizationHeader(token);
      dispatch(verifyF2aSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(verifyF2aFail(error));

      return error;
    });
};

/** RESET VERIFY 2FA **/
const { resetVerifyF2aRequest, resetVerifyF2aSuccess, resetVerifyF2aFail } = createActions({
  RESET_VERIFY_F2A_REQUEST: () => { },
  RESET_VERIFY_F2A_SUCCESS: data => ({ data }),
  RESET_VERIFY_F2A_FAIL: error => ({ error })
});

export const resetVerifyF2a = body => (dispatch) => {
  dispatch(resetVerifyF2aRequest());

  return Api.User.resetVerifyF2a(body)
    .then(({ data }) => {
      dispatch(resetVerifyF2aSuccess(data));
    })
    .catch((error) => {
      dispatch(resetVerifyF2aFail(error));
    });
};

/** GET AES KEY **/
const { fetchAesKeyRequest, fetchAesKeySuccess, fetchAesKeyFail } = createActions({
  FETCH_AES_KEY_REQUEST: () => { },
  FETCH_AES_KEY_SUCCESS: data => ({ data }),
  FETCH_AES_KEY_FAIL: error => ({ error })
});

export const fetchAesKey = () => (dispatch) => {
  dispatch(fetchAesKeyRequest());

  return Api.User.fetchAesKey()
    .then(({ data }) => {
      const aesKey = get(data, 'key', '');
      const aesIv = get(data, 'iv', '');

      http.setInterceptorRequest(aesKey, aesIv);
      dispatch(fetchAesKeySuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchAesKeyFail(error));

      return error;
    });
};

/** FETCH USERS **/
const { fetchUsersRequest, fetchUsersSuccess, fetchUsersFail } = createActions({
  FETCH_USERS_REQUEST: () => { },
  FETCH_USERS_SUCCESS: data => ({ data }),
  FETCH_USERS_FAIL: error => ({ error })
});

export const fetchUsers = () => (dispatch) => {
  dispatch(fetchUsersRequest());

  return Api.User.fetchUsers()
    .then(({ data }) => {
      dispatch(fetchUsersSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchUsersFail(error));
    });
};

/** FETCH USER INFO **/
const { fetchUserInfoRequest, fetchUserInfoSuccess, fetchUserInfoFail } = createActions({
  FETCH_USER_INFO_REQUEST: () => { },
  FETCH_USER_INFO_SUCCESS: data => ({ data }),
  FETCH_USER_INFO_FAIL: error => ({ error })
});

export const fetchUserInfo = (userId) => (dispatch) => {
  dispatch(fetchUserInfoRequest());

  return Api.User.fetchUserInfo(userId)
    .then(({ data }) => {
      dispatch(fetchUserInfoSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchUserInfoFail(error));
    });
};

/** UPLOAD MEDIA **/
const { uploadMediaRequest, uploadMediaSuccess, uploadMediaFail } = createActions({
  UPLOAD_MEDIA_REQUEST: () => { },
  UPLOAD_MEDIA_SUCCESS: data => ({ data }),
  UPLOAD_MEDIA_FAIL: error => ({ error })
});

export const uploadMedia = (body, bucket = 'PDF') => (dispatch) => {
  dispatch(uploadMediaRequest());

  return Api.User.uploadMedia(body, bucket)
    .then(({ data }) => {
      dispatch(uploadMediaSuccess(data));
      return data;
    })
    .catch((error) => {
      dispatch(uploadMediaFail(error));
      return error;
    });
};

import { handleActions } from 'redux-actions';

const initialState = {
  categories: {
    result: [],
    requesting: false,
    error: null
  },
  category: {
    result: null,
    requesting: false,
    error: null
  },
  created: {
    result: null,
    requesting: false,
    error: null
  },
  updated: {
    result: null,
    requesting: false,
    error: null
  },
  deleted: {
    result: null,
    requesting: false,
    error: null
  }
};

export const category = handleActions({
  FETCH_CATEGORIES_REQUEST: (state) => ({
    ...state,
    categories: {
      ...state.categories,
      requesting: true,
      error: null
    }
  }),
  FETCH_CATEGORIES_SUCCESS: (state, { payload }) => ({
    ...state,
    categories: {
      ...state.categories,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_CATEGORIES_FAIL: (state, { payload }) => ({
    ...state,
    categories: {
      ...state.categories,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  FETCH_CATEGORY_REQUEST: (state) => ({
    ...state,
    category: {
      ...initialState.category,
      requesting: true,
      error: null
    }
  }),
  FETCH_CATEGORY_SUCCESS: (state, { payload }) => ({
    ...state,
    category: {
      ...state.category,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_CATEGORY_FAIL: (state, { payload }) => ({
    ...state,
    category: {
      ...state.category,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  CREATE_CATEGORY_REQUEST: (state) => ({
    ...state,
    created: {
      ...state.created,
      requesting: true,
      error: null
    }
  }),
  CREATE_CATEGORY_SUCCESS: (state, { payload }) => ({
    ...state,
    created: {
      ...state.created,
      requesting: false,
      result: payload.data,
      error: null
    },
    categories: {
      ...state.categories,
      result: [
        payload.data,
        ...state.categories.result
      ]
    }
  }),
  CREATE_CATEGORY_FAIL: (state, { payload }) => ({
    ...state,
    created: {
      ...state.created,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  UPDATE_CATEGORY_REQUEST: (state) => ({
    ...state,
    updated: {
      ...state.updated,
      requesting: true,
      error: null
    }
  }),
  UPDATE_CATEGORY_SUCCESS: (state, { payload }) => ({
    ...state,
    updated: {
      ...state.updated,
      requesting: false,
      result: payload.data,
      error: null
    },
    categories: {
      ...state.categories,
      result: state.categories.result.map(item => item.id === payload.data.id ? payload.data : item)
    }
  }),
  UPDATE_CATEGORY_FAIL: (state, { payload }) => ({
    ...state,
    updated: {
      ...state.updated,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  DELETE_CATEGORY_REQUEST: (state) => ({
    ...state,
    deleted: {
      ...state.deleted,
      requesting: true,
      error: null
    }
  }),
  DELETE_CATEGORY_SUCCESS: (state, { payload }) => ({
    ...state,
    deleted: {
      ...state.deleted,
      requesting: false,
      result: payload.data,
      error: null
    },
    categories: {
      ...state.categories,
      result: state.categories.result.filter(item => item.id !== payload.data)
    }
  }),
  DELETE_CATEGORY_FAIL: (state, { payload }) => ({
    ...state,
    deleted: {
      ...state.deleted,
      requesting: false,
      result: null,
      error: payload.error
    }
  })

}, initialState);

export default category;

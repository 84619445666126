import { handleActions } from 'redux-actions';

const initialState = {
  login: {
    result: null,
    error: null,
    requesting: false
  },
  verifyF2a: {
    result: null,
    error: null,
    requesting: false
  },
  resetVerifyF2a: {
    result: null,
    error: null,
    requesting: false
  },
  aesKey: {
    result: [],
    error: null,
    requesting: false
  },
  users: {
    result: [],
    error: null,
    requesting: false
  },
  userInfo: {
    result: {},
    error: null,
    requesting: false
  },
  upload: {
    result: null,
    error: null,
    requesting: false
  }
};

export const user = handleActions({
  /** LOGIN **/
  LOGIN_REQUEST: (state) => ({
    ...state,
    login: {
      ...state.login,
      requesting: true,
      error: null
    }
  }),
  LOGIN_SUCCESS: (state, { payload }) => ({
    ...state,
    login: {
      ...state.login,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  LOGIN_FAIL: (state, { payload }) => ({
    ...state,
    login: {
      ...state.login,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** VERIFY F2A **/
  VERIFY_F2A_REQUEST: (state) => ({
    ...state,
    login: {
      ...state.login,
      requesting: true,
      result: null,
      error: null
    }
  }),
  VERIFY_F2A_SUCCESS: (state, { payload }) => ({
    ...state,
    login: {
      ...state.login,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  VERIFY_F2A_FAIL: (state, { payload }) => ({
    ...state,
    login: {
      ...state.login,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** RESET_VERIFY F2A **/
  RESET_VERIFY_F2A_REQUEST: (state) => ({
    ...state,
    resetVerifyF2a: {
      ...state.resetVerifyF2a,
      requesting: true
    }
  }),
  RESET_VERIFY_F2A_SUCCESS: (state, { payload }) => ({
    ...state,
    resetVerifyF2a: {
      ...state.resetVerifyF2a,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  RESET_VERIFY_F2A_FAIL: (state, { payload }) => ({
    ...state,
    resetVerifyF2a: {
      ...state.resetVerifyF2a,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** FETCH AES KEY **/
  FETCH_AES_KEY_REQUEST: (state) => ({
    ...state,
    aesKey: {
      ...state.aesKey,
      requesting: true,
      result: null,
      error: null
    }
  }),
  FETCH_AES_KEY_SUCCESS: (state, { payload }) => ({
    ...state,
    aesKey: {
      ...state.aesKey,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_AES_KEY_FAIL: (state, { payload }) => ({
    ...state,
    aesKey: {
      ...state.aesKey,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** FETCH USERS **/
  FETCH_USERS_REQUEST: (state) => ({
    ...state,
    users: {
      ...state.users,
      requesting: true
    }
  }),
  FETCH_USERS_SUCCESS: (state, { payload }) => ({
    ...state,
    users: {
      ...state.users,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_USERS_FAIL: (state, { payload }) => ({
    ...state,
    users: {
      ...state.users,
      requesting: false,
      error: payload.error
    }
  }),

  /** FETCH USER INFO **/
  FETCH_USER_INFO_REQUEST: (state) => ({
    ...state,
    userInfo: {
      ...state.userInfo,
      requesting: true
    },
    currentWorkoutListening: {},
    getUserLocationViaSocketError: false
  }),
  FETCH_USER_INFO_SUCCESS: (state, { payload }) => ({
    ...state,
    userInfo: {
      ...state.userInfo,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_USER_INFO_FAIL: (state, { payload }) => ({
    ...state,
    userInfo: {
      ...state.userInfo,
      requesting: false,
      error: payload.error
    }
  }),
  /** UPLOAD **/
  UPLOAD_MEDIA_REQUEST: (state) => ({
    ...state,
    upload: {
      ...state.upload,
      requesting: true
    }
  }),
  UPLOAD_MEDIA_SUCCESS: (state, { payload }) => ({
    ...state,
    upload: {
      ...state.upload,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  UPLOAD_MEDIA_FAIL: (state, { payload }) => ({
    ...state,
    upload: {
      ...state.upload,
      requesting: false,
      error: payload.error
    }
  }),

  /** INITIAL USER DATA **/
  SET_INITIAL_USER_DATA: () => initialState
}, initialState);

export default user;

import User from './user';
import Member from './member';
import Category from './category';
import Post from './post';
import Email from './email';

export default {
  User,
  Member,
  Category,
  Post,
  Email
};

import http from '../services/http';

export default class User {
  static login(body) {
    http.setAuthorizationHeader('');
    return http.post('/users/login', body);
  }

  static verifyF2a(body) {
    http.setAuthorizationHeader('');
    return http.post('/users/verify-2fa', body);
  }

  static resetVerifyF2a(body) {
    http.setAuthorizationHeader('');
    return http.post('/users/reset-2fa', body);
  }

  static fetchAesKey() {
    return http.get('users/get-aes-key');
  }

  static fetchUsers() {
    return http.get('/users');
  }

  static fetchUserInfo(userId) {
    return http.get(`/users/${userId}`, {
      params: {
        filter: {
          include: [
            {
              relation: 'referred'
            },
            {
              relation: 'avatar'
            }
          ]
        }
      }
    });
  }

  static uploadMedia(body, bucket) {
    return http.post(`/media/upload?bucket=${bucket}`, body);
  }
}

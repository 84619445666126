import { get } from 'lodash';
import { clearUserData } from '../../actions/user';

const handleApiServerStatus = store => next => action => {
  const state = store.getState();
  const status = get(action, 'payload.error.status');
  const token = get(state, 'user.login.result.accessToken');

  // If after call an api BE response token is expired error then remove data user and redirect to login page
  if (status === 401 && token) {
    return store.dispatch(clearUserData());
  }

  next(action);
};

export default handleApiServerStatus;
